const UBID_COOKIE = "sfccubid";

// Event types.
const BADGE_VIEW_EVENT = 'widget-viewed';
const PAGE_LOAD_EVENT = 'page-load';
const WIDGET_CLICK_EVENT = 'widget-clicked';
const BWP_WIDGET_PROMISE_LOADED = 'bwp-widget-promise-loaded'

// Page actions.
const PRIME_BADGE_ACTION = 'prime-badge-loaded';
const WIDGET_PROMISE_LOADED = 'widget-loaded';
const ADD_TO_CART = 'add-to-cart';
const REMOVE_FROM_CART = 'remove-from-cart';

// Specific page actions, used to decorate standard prime events to help eliminate ambiguity.
// E.g; If two prime events take place in the product list and mini-cart on the same page,
// these are added respectively, as a prefix to remove ambiguity.
const PRODUCT_ACTION = 'product';
const MINICART_ACTION = 'mini-cart';

// Page types
const PRODUCT_PAGE = 'product-page-widget';
const CHECKOUT_PAGE = 'checkout';
const CONFIRMATION_PAGE = 'confirmation';
const ORDER_DETAILS_PAGE = 'order-details';
const MINICART_PAGE = 'mini-cart';
const CART_TYPE = 'cart';
const UNKNOWN = 'unknown';


// Minor performance enhancement cache variable, prevents requerying cookies for the same information.
let cachedCookieValue;

// Product Summary items displaying a BwP badge, aggregated inside a single event.
let currentProductSummaryItems;
// Gathered clickstream events, ready for dispatch.
let currentEvents = [];

/**
 * Declares a clickstream event array if none exists, then adds the given event to the array.
 * The array acts as a queue for requests to be collected by the transmitter.
 * @param {
 *  current_url,
 *  event_id,
 *  current_timestamp,
 *  store_domain,
 *  user_agent,
 *  ubid,
 *  screen_width,
 *  screen_height,
 *  referrer,
 *  page_action,
 *  sku?,
 *  value?,
 *  discount?,
 *  tax?,
 *  order_id?,
 *  cart_id?
 * } event Clickstream event containing browser information to send to clickstream queue..
 */
function postClickstreamEvent(event) {
    if (!window.clickstreamURL && event && event.url) {
        window.clickstreamURL = event.url;
    }
    if (currentEvents) {
        currentEvents.push(event);
    }
}

/** Posts a standard prime badge viewed event. */
function postPrimeBadgeViewedEvent(url, sku, specificPageAction) {
    if (url && url != "null") {
        postClickstreamEvent({ url, product_sku: sku, ...collectCommonBrowserData(BADGE_VIEW_EVENT, specificPageAction + '-' + PRIME_BADGE_ACTION) });
    }
}

/** Posts a mini-cart specific prime badge viewed event. */
function postMiniCartBadgeViewedEvent(clickstreamURL, sku) {
    postPrimeBadgeViewedEvent(clickstreamURL, sku, MINICART_ACTION);
}

function postPrimeButtonClickedEvent(url, cartItems, pageType) {
    if (!window.clickstreamURL) {
        window.clickstreamURL = url;
    }

    let event = collectCommonBrowserData(WIDGET_CLICK_EVENT, null, pageType)
    event.cart_items = cartItems

    postClickstreamEvent({ url, ...event });
}

function postPrimePromiseViewedEvent(url, mSku, deliveryEstimate, pageType) {
    if (!window.clickstreamURL) {
        window.clickstreamURL = url;
    }

    let event = collectCommonBrowserData(BWP_WIDGET_PROMISE_LOADED, WIDGET_PROMISE_LOADED, pageType);
    event.product_sku = mSku;
    event.prime_promise_timestamp = deliveryEstimate;

    postClickstreamEvent({ url, ...event })
}

/** Posts a product summary event, assigns all current product summary items to a single event. */
function postProductSummaryEvent() {
    // Only run if there are product summary items.
    if (currentProductSummaryItems && currentProductSummaryItems.length > 0) {
        // Getting the page action to determine if pre or post checkout.
        const pageActionElements = document.getElementsByClassName('page');
        let pageType;
        if (pageActionElements && pageActionElements.length > 0) {
            const pageDataAction = pageActionElements[0].getAttribute('data-action');
            pageType = pageDataAction === "Order-Confirm" ? CONFIRMATION_PAGE : CHECKOUT_PAGE
        }
        // Posting event.
        postClickstreamEvent({
            url: currentProductSummaryItems[0].url,
            cart_items: currentProductSummaryItems,
            order_id: getOrderNumber(),
            ...collectCommonBrowserData(PAGE_LOAD_EVENT, null, pageType),
            ...findProductSummaryFinancial()
        });
    }
}

function postOrderDetailsViewedEvent(id) {
    postClickstreamEvent({ order_id: id, ...collectCommonBrowserData(PAGE_LOAD_EVENT, null, ORDER_DETAILS_PAGE) })
}

function postCartClickEvent(pageAction, url, sku, deliveryDate, price, quantity, minicart) {
    if (!window.clickstreamURL) {
        window.clickstreamURL = url;
    }

    let event = collectCommonBrowserData(WIDGET_CLICK_EVENT, pageAction);
    event["page_type"] = (minicart ? 'mini' : '') + CART_TYPE;
    event["product_sku"] = sku;
    event["prime_promise_timestamp"] = deliveryDate;
    event["quantity"] = quantity;
    event["price"] = price;
    event["currency"] = "USD";

    postClickstreamEvent({ url, ...event });
}

/** Function used to get the order number from the default class assignment. */
function getOrderNumber() {
    let orderNumber;

    const orderNumberElement = document.querySelector('.order-number');
    if (orderNumberElement) {
        orderNumber = orderNumberElement.innerHTML;
    }

    return orderNumber;
}

/**
 * Attempts to retrieve a UBID from cookies, used to signify a unique shopper.
 * If not found, generates and sets a new one.
 * @returns UUID string representing a unique shopper, existing or new.
 */
function getUBID() {
    // Prevent needless search if we've already retrieved the cookie.
    if (cachedCookieValue) {
        return cachedCookieValue;
    }
    // Getting existing cookie if exists.
    const cookie = document.cookie.split('; ')
        .find(row => row.startsWith(UBID_COOKIE + '='));

    // If cookie and subsequent value is found, return that.
    if (cookie) {
        const cookieValue = cookie.split("=")[1];

        if (cookieValue) {
            cachedCookieValue = cookieValue;
            return cookieValue;
        }
    }

    // Otherwise generate and return a new cookie.
    const newUBID = crypto.randomUUID();
    cachedCookieValue = newUBID;
    document.cookie = UBID_COOKIE + "=" + newUBID + ";path=/";
    return newUBID;
}

function collectCartClickEvents() {
    const buttons = document.querySelectorAll('.cart #bwp-cart-button');

    buttons.forEach(button => {
        let cartItems = []
        let products = document.querySelectorAll('.product-line-item');
        products.forEach(function (product) {
            let sku = product.getAttribute('amzn-sku')
            let quantity = product.getAttribute('quantity')
            let priceElement = product.querySelector('.unit-price')
            let price;
            if (priceElement) {
                let priceValue = priceElement.querySelector('span.value[content]');
                price = priceValue ? parseFloat(priceValue.getAttribute('content')) : null;
            }
            cartItems.push({ 'SKU': sku, 'quantity': quantity, 'price': price });
        });

        button.addEventListener('click', function (event) {
            let clickedButton = event.target;
            let clickstreamURL = button.getAttribute('clickstream-url');

            let page_type;
            if (clickedButton.closest('.minicart-footer')) {
                page_type = MINICART_PAGE;
            } else {
                // Otherwise check the page data action.
                const pageActionElements = document.querySelectorAll('.page');
                if (pageActionElements && pageActionElements.length > 0) {
                    // Obtaining page data action.
                    const pageDataAction = pageActionElements[0].getAttribute('data-action');
                    if (pageDataAction == 'Cart-Show') {
                        page_type = CART_TYPE;
                    } else {
                        page_type = CHECKOUT_PAGE;
                    }
                } else {
                    page_type = UNKNOWN;
                }
            }

            postPrimeButtonClickedEvent(clickstreamURL, cartItems, page_type)
            dispatchCurrentEvents();
        });
    })
}

function collectManageOrderClickEvents() {
    const manageOrderElement = document.querySelector('[amzn-order-id]')
    if (manageOrderElement) {
        manageOrderElement.addEventListener('mousedown', () => {
            const id = manageOrderElement.getAttribute('amzn-order-id');
            if (id && id != "null") {
                postOrderDetailsViewedEvent(id);
                dispatchCurrentEvents();
            }
        });
    }
}

function collectAddToCartClickEvent(primePromiseData, primeContainer) {
    $(document).on('click', '.add-to-cart', function () {
        const clickstreamURLElement = document.querySelector("[clickstream-url]");
        const clickstreamURL = getClickstreamURL(clickstreamURLElement);
        const bwpProduct = window.bwpProduct;
        let mSku, deliveryDate, price, quantity;

        // set promise data
        if (primePromiseData && primePromiseData.earliest) {
            deliveryDate = new Date(primePromiseData.earliest).getTime();
        }

        // set the price
        let productPrice = document.querySelectorAll('.prices-add-to-cart-actions');
        productPrice.forEach(function (product) {
            priceElement = product.querySelector('.sales')
            if (priceElement) {
                let priceValue = priceElement.querySelector('span.value[content]');
                price = priceValue ? parseFloat(priceValue.getAttribute('content')) : null;
            }
        })

        // find sku
        if (bwpProduct && bwpProduct.mSku) {
            mSku = bwpProduct.mSku;
        } else {
            if (primeContainer && primeContainer.length > 0) {
                mSku = findSku(primeContainer[0]);
            }
        }

        // find quantity
        let products = document.querySelectorAll('.product-line-item');
        products.forEach(function (product) {
            quantity = product.getAttribute('quantity')
        });

        const selectedOption = document.querySelector('.quantity-select option[selected]');
        quantity = selectedOption.value;

        postCartClickEvent(ADD_TO_CART, clickstreamURL, mSku, deliveryDate, price, quantity);
        dispatchCurrentEvents();
    });
}

function collectRemoveFromCartClickEvent() {
    $(document).on('click', '.cart-delete-confirmation-btn[data-dismiss="modal"]', function () {
        const clickstreamURLElement = document.querySelector("[clickstream-url]");
        const clickstreamURL = getClickstreamURL(clickstreamURLElement);
        let mSku, deliveryDate, price, quantity;
        let productToRemoveElem = document.querySelector('.product-to-remove');
        let productToRemove = productToRemoveElem.textContent;

        const lineItemNames = document.getElementsByClassName('line-item-name');
        for (let i = 0; i < lineItemNames.length; i++) {
            const lineItemElem = lineItemNames[i]
            let productName = lineItemElem.innerText.trim();
            if (productName == productToRemove) {
                const cardElement = lineItemElem.closest('.card');
                const productLineItemElement = lineItemElem.closest('.product-line-item');
                // It was removed on the full-cart
                if (productLineItemElement) {
                    mSku = productLineItemElement.getAttribute('amzn-sku')
                    // Only care about prime items.
                    if (mSku != "null") {
                        quantity = productLineItemElement.getAttribute('quantity')
                        let priceElement = productLineItemElement.querySelector('.unit-price')
                        if (priceElement) {
                            let priceValue = priceElement.querySelector('span.value[content]');
                            price = priceValue ? parseFloat(priceValue.getAttribute('content')) : null;
                        }
                        postCartClickEvent(REMOVE_FROM_CART, clickstreamURL, mSku, deliveryDate, price, quantity, true);
                    }
                    // Full cart removal.
                } else if (cardElement) {
                    const primeContainer = cardElement.querySelector('.bwp-prime-container');
                    // Only care about prime items.
                    if (primeContainer && !primeContainer.classList.contains('hidden')) {
                        const quantityElem = cardElement.querySelector('[selected]');
                        if (quantityElem) {
                            quantity = quantityElem.innerHTML;
                        }
                        const salesElem = cardElement.querySelector('.unit-price');
                        if (salesElem) {
                            const priceElem = salesElem.querySelector('.value');
                            if (priceElem) {
                                price = extractNumericValue(priceElem.innerText);
                            }
                        }
                        mSku = findSku(primeContainer);
                        deliveryDate = primeContainer.getAttribute('amzn-earliest');
                        postCartClickEvent(REMOVE_FROM_CART, clickstreamURL, mSku, deliveryDate, price, quantity, false);

                    }
                }
                dispatchCurrentEvents();
                break;
            }
        }
    });
}

/**
 * Collects common browser data for the event at the time the event happened.
 * @param {*} eventName Name of the event.
 * @param {*} pageAction Action page was taking when the event happened.
 * @returns object containing common browser data for clickstream eventing.
 */
function collectCommonBrowserData(eventName, pageAction, pageType) {
    const event = {
        current_url: window.location.href,
        event_id: crypto.randomUUID(),
        current_timestamp: new Date().toISOString(),
        store_domain: window.location.origin,
        user_agent: window.navigator.userAgent,
        ubid: getUBID(),
        screen_width: window.screen.width,
        screen_height: window.screen.height,
        referrer: document.referrer,
        event_name: eventName
    };

    if (pageAction) {
        event.page_action = pageAction;
    }

    if (pageType) {
        event.page_type = pageType;
    }

    return event;
}

/**
 * Sends all events that are currently in the queue.
 */
function dispatchCurrentEvents() {
    const url = window.clickstreamURL;
    // Maximum size per request.
    const batchSize = 10;

    if (currentEvents && currentEvents.length > 0) {
        for (let i = 0; i < currentEvents.length; i += batchSize) {
            // Gather batch to send.
            const eventsToSend = currentEvents.slice(i, i + batchSize);
            // Defining/sending the ajax request.
            $.ajax({
                url,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify(eventsToSend)
            });
        }
        currentEvents.length = 0;
    }
}

/**
 * Finds the amazon sku based on a specific attribute. Will search parents and then children for a matching attribute.
 * @param element - Element which may contain the amzn-sku.
 * @returns Amazon SKU for a given element if it exists.
 */
function findSku(element) {
    let sku;
    if (element) {
        const parentSkuElement = element.closest('[amzn-sku]');
        const childSkuElement = element.querySelector('[amzn-sku]');
        if (parentSkuElement) {
            sku = parentSkuElement.getAttribute('amzn-sku');
        } else if (childSkuElement) {
            sku = childSkuElement.getAttribute('amzn-sku');
        }
    }
    return sku;
}

/**
 * Temporary function which attempts to find a clickstream URL in a given element.
 * @param element - Element which may contain the URL.
 * @returns URL value for a given element.
 */
function getClickstreamURL(element) {
    if (element) {
        return element.getAttribute('clickstream-url');
    }
}

/**
 * Obtains the delivery promise shown to a shopper from a set attribute.
 * @param element - Element which may contain the promise.
 * @returns Delivery date in millis.
 */
function getDeliveryDate(element) {
    let deliveryDate;
    if (element) {
        const deliveryDateElement = element.closest('[delivery-date-earliest]');
        if (deliveryDateElement) {
            deliveryDate = deliveryDateElement.getAttribute('delivery-date-earliest');
            if (deliveryDate) {
                deliveryDate = new Date(deliveryDate).getTime();
            }
        }
    }
    return deliveryDate;
}

/**
 * Searches a given element for prime badge related data.
 * @param {HTMLElement} element - Initial search element.
 * @returns Prime Badge clickstream object with found values.
 */
function getPrimeBadgeData(element) {
    return {
        sku: findSku(element),
        url: getClickstreamURL(element)
    }
}

/**
 * Searches a given element for product summary related data.
 * @param {HTMLElement} element - Initial search element.
 * @returns ProductSummary Clickstream object with found values.
 */
function getProductSummaryData(element) {
    const quantityElement = element.querySelector('.qty-card-quantity-count');

    let quantity;
    if (quantityElement) {
        quantity = quantityElement.innerHTML;
    }

    return {
        SKU: findSku(element),
        url: getClickstreamURL(element),
        prime_promise_timestamp: getDeliveryDate(element),
        quantity
    }
}

/**
 * Removes the currency symbol from a given currency string.
 * @param {String} currencyString 
 * @returns Only the value of the currency. E.g; $5.12 becomes 5.12.
 */
function extractNumericValue(currencyString) {
    const matches = currencyString.match(/-?\d+(\.\d+)?/);
    return matches ? parseFloat(matches[0]) : null;
}

/**
 * Attempts to find all of the financial details for a basket shown to a shopper.
 * Used for product summary events.
 * @returns Object containing financial summary details.
 */
function findProductSummaryFinancial() {
    const grandTotalElem = document.querySelector('.grand-total-sum');
    let grandTotal;
    if (grandTotalElem) {
        grandTotal = extractNumericValue(grandTotalElem.innerHTML);
    }

    const taxTotalElem = document.querySelector('.tax-total');
    let taxTotal;
    if (taxTotalElem) {
        taxTotal = extractNumericValue(taxTotalElem.innerHTML);
    }

    const discountTotalElem = document.querySelector('.order-discount-total');
    let discountTotal;
    if (discountTotalElem) {
        discountTotal = extractNumericValue(discountTotalElem.innerHTML);
    }

    return { value: grandTotal, tax: taxTotal, discount: discountTotal };
}

/**
 * Scans an element to see if a bwp container exists and is visible.
 * Used to ensure that only product summaries with a visible prime container are collected.
 * @param {HTMLElement} element - Initial search element. 
 * @returns {boolean} True if a visible prime container exists.
 */
function hasVisibleBwPContainer(element) {
    let found = false;
    checkoutContainerElement = element.querySelector('.bwp-container-checkout');
    if (checkoutContainerElement) {
        const primeContainer = element.querySelector('.bwp-prime-container');
        if (primeContainer && !primeContainer.classList.contains('hidden')) {
            found = true;
        }
    }

    return found;
}

function findClickstreamObjects(element) {
    if (element) {
        return element.querySelectorAll('[clickstream-object]');
    }
}

/**
 * Function use to collect all clickstream events events related to initial page loading.
 */
function collectElementRenderEvents() {
    window.addEventListener('load', function () {
        currentProductSummaryItems = [];
        // Events are denoted by adding a clickstream object attribute.
        const dataTypes = findClickstreamObjects(document);
        dataTypes.forEach(element => {
            // Gathering data from the hierarchy.
            const clickstreamObjectType = element.getAttribute('clickstream-object');
            // Switch on object type to accurately record which event was processed.
            switch (clickstreamObjectType) {
                case 'primeBadge':
                    const primeBadgeData = getPrimeBadgeData(element);
                    postPrimeBadgeViewedEvent(primeBadgeData.url, primeBadgeData.sku, PRODUCT_ACTION)
                    break;
                case 'productSummary':
                    if (hasVisibleBwPContainer(element)) {
                        const productSummaryData = getProductSummaryData(element);
                        currentProductSummaryItems.push(productSummaryData);
                    }
                    break;
            }
        });
        // Convert product summary items to a single event if found.
        postProductSummaryEvent();

        // Dispatching after iterating.
        dispatchCurrentEvents();
    });
}

/**
 * Collects information related to a prime promise viewed event.
 * @param {*} primePromiseData Date related to the prime promise that was shown to the user.
 */
function collectPrimePromiseEvent(primePromiseData, primeContainer) {
    if (primeContainer && primeContainer.length > 0) {
        // Ensuring we can obtain the required clickstream URL.
        const clickstreamURL = getClickstreamURL(primeContainer[0]);

        if (clickstreamURL && clickstreamURL != "null") {
            let mSku;
            let deliveryDate;

            const bwpProduct = window.bwpProduct;
            // If function was called from product page, mSku should be defined in the product.
            if (bwpProduct && bwpProduct.mSku) {
                mSku = bwpProduct.mSku;
            } else {
                // Otherwise, we need to find the sku from the prime container.
                mSku = findSku(primeContainer[0]);
            }

            // Attempt to convert prime promise to millis.
            if (primePromiseData && primePromiseData.earliest) {
                deliveryDate = new Date(primePromiseData.earliest).getTime();
                primeContainer[0].setAttribute("amzn-earliest", deliveryDate);
            }

            // Post and dispatch.
            postPrimePromiseViewedEvent(clickstreamURL, mSku, deliveryDate, PRODUCT_PAGE);
            dispatchCurrentEvents();
        }
    }
}

// Initial collection call.
collectRemoveFromCartClickEvent();
collectElementRenderEvents();
collectManageOrderClickEvents();
collectCartClickEvents();

// Attaching functions to the window for use in other clickstream workflows.
module.exports = function () {
    window.postMiniCartBadgeViewedEvent = postMiniCartBadgeViewedEvent;
    window.collectPrimePromiseEvent = collectPrimePromiseEvent;
    window.collectAddToCartClickEvent = collectAddToCartClickEvent;
    window.collectCartClickEvents = collectCartClickEvents;
    window.dispatchCurrentEvents = dispatchCurrentEvents;
    window.findSku = findSku;
    window.getClickstreamURL = getClickstreamURL;
    window.findClickstreamObjects = findClickstreamObjects;
}