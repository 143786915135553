'use strict';

/**
 * Attempts to find the default mini-cart popover and attach a MutationObserver to it.
 * If the MutationObserver is successfully attached, it will watch for cart updates
 * and send clickstream events if it finds a BwP badge on an eligible item.
 */
module.exports = function () {
    const miniCartContainer = $('.popover-bottom');
    if (miniCartContainer && miniCartContainer.length > 0) {
        // Defining te observer
        const observer = new MutationObserver(function (mutations) {
            // Iterate trough the mutations, specifically looking for child additions.
            mutations.forEach(function (mutation) {
                if (mutation.type === 'childList') {
                    // Used to avoid unnecessary dispatch calls.
                    let eventPosted = false;

                    // Getting all the clickstream objects in the mini-cart, which should only be badge events.
                    const miniCartBadgeObjects = window.findClickstreamObjects(miniCartContainer[0]);
                    if (miniCartBadgeObjects) {
                        miniCartBadgeObjects.forEach(badgeObject => {
                            // Only processing each cart update once, using attributes to track.
                            if (!badgeObject.getAttribute('bwp-cstream-processed')) {
                                // Obtaining the clickstream information.
                                const clickstreamUrl = window.getClickstreamURL(badgeObject);
                                const amazonSku = window.findSku(badgeObject);

                                // Posting event if URL was properly found.
                                if (clickstreamUrl) {
                                    postMiniCartBadgeViewedEvent(clickstreamUrl, amazonSku);
                                    eventPosted = true;
                                }
                                // Marking the element as processed to ensure no duplicate events are sent.
                                badgeObject.setAttribute('bwp-cstream-processed', 'true');
                            }
                        });
                        // If event was posted then call a dispatch.
                        if (eventPosted) {
                            dispatchCurrentEvents();
                            collectCartClickEvents();
                        }
                    }
                }
            });
        });

        // Attaching the observer.
        observer.observe(miniCartContainer[0], {
            attributes: true,
            subtree: true,
            childList: true
        });
    }

}